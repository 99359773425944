@font-face {
    font-family: 'SatoshiVariable';
    src: url('./Satoshi_Complete/Fonts/Variable/Satoshi-Variable.ttf') format('truetype'); 
  }
  
  @font-face {
    font-family: 'EBGaramond';
    src: url('./EB_Garamond/EBGaramond-Italic-VariableFont_wght.ttf') format('truetype');
  }
  
  body {
    font-family: 'CustomFont', sans-serif;
  }